<template>
    <li class="feature" @mouseover="onMouseOver" @click.stop="onClick">
        <div class="feature-details-wrapper">
            <h6 class="feature-header">{{ name }}</h6>
            <p v-if="description" class="feature-description text-truncate" v-html="description" v-linkify />
        </div>
    </li>
</template>

<script>
import { get, debounce } from 'utils/lodash';
export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        properties() {
            return get(this.row, 'properties');
        },
        name() {
            return get(this.properties, 'name');
        },
        description() {
            return get(this.properties, 'description');
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.row);
        }, 250),
        onClick() {
            this.$emit('click', this.row);
        },
    },
};
</script>
